import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";
import {
  CrudComponent,
  actionBtnTypes,
} from "../../../../../../../components/boda-boda/CRUD/CrudComponent";
import { orderCSVUploadAPI, orderCSVUploadDedicatedAPI } from "../../../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import UploadPackageModalUI from "./UploadPackageModalUI";
import { callApi } from "../../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { setPageLoader } from "../../../../../../../store/boda-boda/ui/actions";
import {
  downloadCSV,
  isEmptyValue,
} from "../../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { fileGetAPIUrl } from "../../../../../../../config/boda-boda/core.config";

class UploadPackageCSV extends Component {
  onPackageDownloadFn = (id, fileName) => {
    let { setPageLoader } = this.props;

    setPageLoader(true);
    callApi(`${orderCSVUploadDedicatedAPI.url}/${id}/data`)
      .headers(true)
      .method("get")
      .send()
      .then((result) => {
        this.generatePackageCSVData(get(result, "data.data", []), fileName);
        setPageLoader(false);
      })
      .catch((error) => {
        setPageLoader(false);
      });
  };

  generatePackageCSVData = (data = [], fileName = "") => {
    const includedHeaders = [];
    for (let x in data[0].data) {
      includedHeaders.push(x);
    }
    let headers = [];
    if (includedHeaders.includes("SIGNATURE_REQUIRED")) {
      headers = [
        "CHANNEL_ORDER_NO",
        "NAME",
        "EMAIL",
        "PHONE",
        "COUNTRY_CODE_PREFIX",
        "DELIVERY_TYPE",
        "ID_VERIFICATION_REQUIRED",
        "AMOUNT",
        "PAYMENT_METHOD",
        "SIGNATURE_REQUIRED",
        "SHIPPING_LOCATION_ADDRESS",
        includedHeaders.includes("SHIPPING_LOCATION_POSTAL_CODE")
          ? "SHIPPING_LOCATION_POSTAL_CODE"
          : "SHIPPING_LOCATION_CITY_ID",
        "PACKAGE_TYPE_ID",
        "PACKAGE_CATEGORY_ID",
        "PACKAGE_WEIGHT",
        "PACKAGE_HEIGHT",
        "PACKAGE_LENGTH",
        "PACKAGE_WIDTH",
        "PACKAGE_PICK_LOCATION_ID",
        "PACKAGE_NOTES_PUBLIC",
        "PACKAGE_NOTES_PRIVATE",
        get(data[0], "file.status", "-") === "SUCCESS"
          ? "REMARK"
          : get(data[0], "file.status", "-") === "FAILED"
          ? "ERRORS"
          : '"REMARK","ERRORS"',
      ];
    } else if (
      !includedHeaders.includes("SIGNATURE_REQUIRED") &&
      includedHeaders.includes("PACKAGE_PICK_LOCATION_ID")
    ) {
      headers = [
        "CHANNEL_ORDER_NO",
        "NAME",
        "EMAIL",
        "PHONE",
        "AMOUNT",
        "PAYMENT_METHOD",
        "SHIPPING_LOCATION_ADDRESS",
        includedHeaders.includes("SHIPPING_LOCATION_POSTAL_CODE")
          ? "SHIPPING_LOCATION_POSTAL_CODE"
          : "SHIPPING_LOCATION_CITY_ID",
        "PACKAGE_WEIGHT",
        "PACKAGE_PICK_LOCATION_ID",
        "PACKAGE_NOTES_PUBLIC",
        "PACKAGE_NOTES_PRIVATE",
        get(data[0], "file.status", "-") === "SUCCESS"
          ? "REMARK"
          : get(data[0], "file.status", "-") === "FAILED"
          ? "ERRORS"
          : '"REMARK","ERRORS"',
      ];
    } else {
      headers = [
        "CHANNEL_ORDER_NO",
        "NAME",
        "EMAIL",
        "PHONE",
        "AMOUNT",
        "PAYMENT_METHOD",
        "SHIPPING_LOCATION_ADDRESS",
        includedHeaders.includes("SHIPPING_LOCATION_POSTAL_CODE")
          ? "SHIPPING_LOCATION_POSTAL_CODE"
          : "SHIPPING_LOCATION_CITY_ID",
        "PACKAGE_WEIGHT",
        "PACKAGE_NOTES_PUBLIC",
        "PACKAGE_NOTES_PRIVATE",
        get(data[0], "file.status", "-") === "SUCCESS"
          ? "REMARK"
          : get(data[0], "file.status", "-") === "FAILED"
          ? "ERRORS"
          : '"REMARK","ERRORS"',
      ];
    }

    const dataList = [];

    data.forEach((value) => {
      const temp = [];
      temp.push(
        get(value, "data.CHANNEL_ORDER_NO", "-") === ""
          ? "-"
          : get(value, "data.CHANNEL_ORDER_NO", "-")
      );
      temp.push(
        get(value, "data.NAME", "-") === "" ? "-" : get(value, "data.NAME", "-")
      );
      temp.push(
        get(value, "data.EMAIL", "-") === ""
          ? "-"
          : get(value, "data.EMAIL", "-")
      );
      temp.push(
        get(value, "data.PHONE", "-") === ""
          ? "-"
          : get(value, "data.PHONE", "-")
      );
      if (includedHeaders.includes("SIGNATURE_REQUIRED")) {
        temp.push(
          get(value, "data.COUNTRY_CODE_PREFIX", "-") === ""
            ? "-"
            : get(value, "data.COUNTRY_CODE_PREFIX", "-")
        );
        temp.push(
          get(value, "data.DELIVERY_TYPE", "-") === ""
            ? "-"
            : get(value, "data.DELIVERY_TYPE", "-")
        );
        temp.push(
          get(value, "data.ID_VERIFICATION_REQUIRED", "-") === ""
            ? "-"
            : get(value, "data.ID_VERIFICATION_REQUIRED", "-")
        );
      }
      temp.push(
        get(value, "data.AMOUNT", "-") === ""
          ? "-"
          : get(value, "data.AMOUNT", "-")
      );
      temp.push(
        get(value, "data.PAYMENT_METHOD", "-") === ""
          ? "-"
          : get(value, "data.PAYMENT_METHOD", "-")
      );
      if (includedHeaders.includes("SIGNATURE_REQUIRED")) {
        temp.push(
          get(value, "data.SIGNATURE_REQUIRED", "-") === ""
            ? "-"
            : get(value, "data.SIGNATURE_REQUIRED", "-")
        );
      }
      temp.push(
        get(value, "data.SHIPPING_LOCATION_ADDRESS", "-") === ""
          ? "-"
          : '"' + get(value, "data.SHIPPING_LOCATION_ADDRESS", "-") + '"'
      );
      if (includedHeaders.includes("SHIPPING_LOCATION_POSTAL_CODE")) {
        temp.push(
          get(value, "data.SHIPPING_LOCATION_POSTAL_CODE", "-") === ""
            ? "-"
            : `'${get(value, "data.SHIPPING_LOCATION_POSTAL_CODE", "-")}'`
        );
      }
      if (includedHeaders.includes("SHIPPING_LOCATION_CITY_ID")) {
        temp.push(
          get(value, "data.SHIPPING_LOCATION_CITY_ID", "-") === ""
            ? "-"
            : get(value, "data.SHIPPING_LOCATION_CITY_ID", "-")
        );
      }
      if (includedHeaders.includes("SIGNATURE_REQUIRED")) {
        temp.push(
          get(value, "data.PACKAGE_TYPE_ID", "-") === ""
            ? "-"
            : get(value, "data.PACKAGE_TYPE_ID", "-")
        );
        temp.push(
          get(value, "data.PACKAGE_CATEGORY_ID", "-") === ""
            ? "-"
            : get(value, "data.PACKAGE_CATEGORY_ID", "-")
        );
      }
      temp.push(
        get(value, "data.PACKAGE_WEIGHT", "-") === ""
          ? "-"
          : get(value, "data.PACKAGE_WEIGHT", "-")
      );
      if (includedHeaders.includes("SIGNATURE_REQUIRED")) {
        temp.push(
          get(value, "data.PACKAGE_HEIGHT", "-") === ""
            ? "-"
            : get(value, "data.PACKAGE_HEIGHT", "-")
        );
        temp.push(
          get(value, "data.PACKAGE_LENGTH", "-") === ""
            ? "-"
            : get(value, "data.PACKAGE_LENGTH", "-")
        );
        temp.push(
          get(value, "data.PACKAGE_WIDTH", "-") === ""
            ? "-"
            : get(value, "data.PACKAGE_WIDTH", "-")
        );
      }
      if (
        includedHeaders.includes("SIGNATURE_REQUIRED") ||
        includedHeaders.includes("PACKAGE_PICK_LOCATION_ID")
      ) {
        temp.push(
          get(value, "data.PACKAGE_PICK_LOCATION_ID", "-") === ""
            ? "-"
            : get(value, "data.PACKAGE_PICK_LOCATION_ID", "-")
        );
      }
      temp.push(
        get(value, "data.PACKAGE_NOTES_PUBLIC", "-") === ""
          ? "-"
          : get(value, "data.PACKAGE_NOTES_PUBLIC", "-")
      );
      temp.push(
        get(value, "data.PACKAGE_NOTES_PRIVATE", "-") === ""
          ? "-"
          : get(value, "data.PACKAGE_NOTES_PRIVATE", "-")
      );

      let errors = "";

      if (get(value, "errors", null) !== null) {
        get(value, "errors", []).forEach((error, index) => {
          if (index === 0) {
            errors = error.error;
          } else {
            errors = `${errors} / ${error.error}`;
          }
        });
      }
      if (get(value, "file.status", "-") === "SUCCESS") {
        temp.push(get(value, "data.remark"));
      } else if (get(value, "file.status", "-") === "FAILED") {
        temp.push(`"${errors}"`);
      } else {
        temp.push(get(value, "data.remark"));
        temp.push(`"${errors}"`);
      }

      dataList.push(temp);
    });

    const finalDataSet = [headers];

    dataList.forEach((value) => {
      finalDataSet.push(value);
    });

    const csvDataContent = finalDataSet.map((e) => e.join(",")).join("\n");
    downloadCSV(csvDataContent, fileName);
  };

  onDownloadFn = (id, fileName) => {
    let { setPageLoader } = this.props;

    setPageLoader(true);

    callApi(`${orderCSVUploadDedicatedAPI.url}/${id}/data`)
      .headers(true)
      .method("get")
      .send()
      .then((result) => {
        this.generateCSVData(get(result, "data.data", []), fileName);
        setPageLoader(false);
      })
      .catch((error) => {
        setPageLoader(false);
      });
  };

  generateCSVData = (data = [], fileName = "") => {
    const headers = [
      "channelId",
      "channelOrderNo",
      "channelOrderRef",
      "name",
      "email",
      "countryCode",
      "phone",
      "deliveryType",
      "idVerificationRequired",
      "notesPublic",
      "notesPrivate",
      "amount",
      "paymentMethod",
      "signatureRequired",
      "shippingLocationName",
      "shippingLocationAddress",
      "shippingLocationPostalCode",
      "shippingLocationPlusCode",
      "shippingLocationLat",
      "shippingLocationLon",
      "shippingLocationCityId",
      "itemName",
      "itemQuantity",
      "itemProductNo",
      "remark",
      "errors",
    ];

    const dataList = [];

    data.forEach((value) => {
      const temp = [];

      temp.push(get(value, "data.channelId", ""));
      temp.push(get(value, "data.channelOrderNo", ""));
      temp.push(get(value, "data.channelOrderRef", ""));
      temp.push(get(value, "data.name", ""));
      temp.push(get(value, "data.email", ""));
      temp.push(get(value, "data.countryCodePrefix", ""));
      temp.push(get(value, "data.phone", ""));
      temp.push(get(value, "data.deliveryType", ""));
      temp.push(get(value, "data.idVerificationRequired", ""));
      temp.push(get(value, "data.notesPublic", ""));
      temp.push(get(value, "data.notesPrivate", ""));
      temp.push(get(value, "data.amount", ""));
      temp.push(get(value, "data.paymentMethod", ""));
      temp.push(get(value, "data.signatureRequired", ""));
      temp.push(get(value, "data.shippingLocationName", ""));
      temp.push(get(value, "data.shippingLocationAddress", ""));
      temp.push(get(value, "data.shippingLocationPostalCode", ""));
      temp.push(get(value, "data.shippingLocationPlusCode", ""));
      temp.push(get(value, "data.shippingLocationLat", ""));
      temp.push(get(value, "data.shippingLocationLon", ""));
      temp.push(get(value, "data.shippingLocationCityId", ""));
      temp.push(get(value, "data.itemName", ""));
      temp.push(get(value, "data.itemQuantity", ""));
      temp.push(get(value, "data.itemProductNo", ""));
      temp.push(get(value, "remark", ""));

      let errors = "";

      if (get(value, "errors", null) !== null) {
        get(value, "errors", []).forEach((error, index) => {
          if (index === 0) {
            errors = error.error;
          } else {
            errors = `${errors} / ${error.error}`;
          }
        });
      }

      temp.push(errors);
      dataList.push(temp);
    });

    const finalDataSet = [headers];

    dataList.forEach((value) => {
      finalDataSet.push(value);
    });

    const csvDataContent = finalDataSet.map((e) => e.join(",")).join("\n");
    downloadCSV(csvDataContent, fileName);
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}></Col>

            <Col sm={6}>
              <div
                className={"d-flex justify-content-end"}
                style={{ padding: "16px 0 16px 0" }}
              >
                <UploadPackageModalUI />
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Uploaded Orders"
              apiURL={`${orderCSVUploadAPI.url}?page=1&limit=20`}
              dataTableStateKey={orderCSVUploadAPI.key}
              isFilter={false}
              isSetCreateAction={false}
              tableHeaderList={[
                {
                  displayName: "Uploaded At",
                  key: "createdAt",
                  onPrintFn: (rawData) =>
                    dateObjectToString(rawData.createdAt, "YY-mm-dd HH:MM:II"),
                },
                {
                  displayName: "File Name",
                  key: "name",
                  onPrintFn: (rawData) => {
                    if (isEmptyValue(rawData.fileName)) {
                      return rawData.name;
                    } else {
                      return rawData.fileName;
                    }
                  },
                },
                { displayName: "Status", key: "status" },
                {
                  displayName: "Category",
                  key: "category",
                  onPrintFn: (rawData) => {
                    if (rawData.category === "ORDER") {
                      return rawData.category + " CSV";
                    } else {
                      return rawData.category + " CSV";
                    }
                  },
                },
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.other,
                  isSetModel: false,
                  actionBtn: {
                    tooltip: "Download",
                    btnText: "download",
                    icon: "fas fa-file-download",
                    color: "primary",
                    onClickBtnFn: (rawData) => {
                      let fileName = rawData.fileName;

                      if (isEmptyValue(rawData.fileName)) {
                        fileName = rawData.name;
                      } else if (rawData.category === "ORDER") {
                        this.onDownloadFn(rawData.id, fileName);
                      } else {
                        this.onPackageDownloadFn(rawData.id, fileName);
                      }
                    },
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    if (rawData.rowData.status === "PENDING") {
                      return false;
                    } else {
                      return true;
                    }
                  },
                },
              ]}
            />
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, { setPageLoader })(UploadPackageCSV);
