import React from "react";
import { InputButton } from "../../../../../components/boda-boda/Forms";
import { convertJsonToCSV, downloadCSV } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";

const DownloadProcessedCsv = (props) => {
    const getCsv = async () => {
        try {
            const response = await fetch(props?.url);
            const jsonData = await response.json();
            const csvData = convertJsonToCSV(jsonData)
            downloadCSV(csvData, "Processed CSV.csv")
        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <InputButton
            btnText={"Download Processed CSV"}
            elementWrapperStyle={"float-right mb-2 mr-1"}
            startIcon={"mdi mdi-check-circle-outline"}
            onClickBtnFn={() => getCsv()}
        />
    )
};

export default DownloadProcessedCsv;
