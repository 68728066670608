import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getManifestListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { CreateUI, PickRequestFilterUI } from "../common-includes/common-includes";
import { actionBtnTypes } from "../../../../../components/boda-boda/CRUD/configs/crud.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { modalTypes } from "../../../../../components/boda-boda/Modal/config/model.config";
import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import CreateManifestBtn from "../common-includes/CreateManifestBtn";
import { SendRequestUIBtn, SendRequestUIMessage } from "../common-includes/SendRequestUI";
import { mapStatusesNames } from "../../../../../config/boda-boda/db_config/db_status.config";
import { formatToDateHelper, isEmptyValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { ManifestPrintBody, PrintManifestBtn } from "../common-includes/PrintUI";
import { setFormErrorFn } from "../../../../../store/boda-boda/form/actions";


class AllRequests extends Component {
    render() {
        let { history, location } = this.props;

        return (
            <CrudComponent
                title={"All"}
                apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20`}
                dataTableStateKey={getManifestListAPI.key}
                isFilter={true}
                defaultFilterFormObject={{
                    pickupDateFrom: null,
                    pickupDateTo: null,
                    code: "",
                    articleId: "",
                    status: "",
                    locationPickId: ""
                }}
                clearErrors={() => {
                    const { setFormErrorFn } = this.props;
                    setFormErrorFn("manifestListAPIKey_search", [])
                }}
                onRebuildFilterObjFn={(filterData) => {
                    const data = filterData;
                    let { setFormErrorFn } = this.props;
                    const errors = [];
                
                    if (new Date(filterData.pickupDateFrom) > new Date(filterData.pickupDateTo)) {
                        errors.push({
                            "property":"pickupDateTo",
                            "error":"The Date To field should not be less than the Date From field"
                        })
                    }

                    if(errors.length > 0 ){
                        setFormErrorFn("manifestListAPIKey_search", errors);
                        return ;
                    }else{
                        setFormErrorFn("manifestListAPIKey_search", []);
                    }
             
                    if (!isEmptyValue(filterData.pickupDateTo)) {
                        data["pickupDateTo"] = formatToDateHelper(filterData.pickupDateTo);
                    }

                    if (!isEmptyValue(filterData.pickupDateFrom)) {
                        data["pickupDateFrom"] = filterData.pickupDateFrom.toISOString();
                    }

                    return data;
                }}
                filterFromComponent={PickRequestFilterUI}
                isSetCreateAction={true}
                createActionProps={{
                    actionType: actionBtnTypes.create,
                    isSetModel: true,
                    modalProps: {
                        modalTitle: "Create Pick Request",
                        modalType: "",
                        modalBodyComponent: CreateUI,
                        modalFooterComponent: CreateManifestBtn,
                        isSetClose: true,
                        isSetFooterClose: true,
                        parentToModelProps: {
                            formKey: "_create",
                            uiType: "create",
                        },
                    }
                }}
                tableHeaderList={[
                    { displayName: "Manifest Code", key: "code" },
                    { displayName: "Created Time", key: "createdAt", onPrintFn: (rawData) => (rawData.createdAt) ? dateObjectToString(rawData.createdAt, "YY-mm-dd HH:MM:II") : "-" },
                    { displayName: "To be Picked Up", key: "pickAt", onPrintFn: (rawData) => (rawData.pickAt) ? dateObjectToString(rawData.pickAt, "YY-mm-dd") : "-" },
                    { displayName: "Picked up Time", key: "pickedAt", onPrintFn: (rawData) => (rawData.pickedAt) ? dateObjectToString(rawData.pickedAt, "YY-mm-dd HH:MM:II") : "-" },
                    { displayName: "Pick Up Location", key: "locationPick.name" },
                    {
                        displayName: "Status", key: "status", onPrintFn: (rawData) => {
                            return mapStatusesNames[rawData.status];
                        }
                    }
                ]}
                actionList={[
                    {
                        actionType: actionBtnTypes.view,
                        isSetModel: false,
                        actionBtn: {
                            onClickBtnFn: (rawData) => history.push(`/pick-request/${location.pathname.split('/')[2]}/view/${rawData.id}`),
                        }
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        isSetModel: false,
                        actionBtn: {
                            onClickBtnFn: (rawData) => history.push(`/pick-request/all/edit/${rawData.id}`),
                        },
                        checkBeforeActionRenderFn: (rawData) => {
                            return rawData.rowData.status === "DRAFT";
                        }
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        isSetModel: false,
                        actionBtn: {
                            icon: "fas fa-redo-alt",
                            tooltip: "Re-draft",
                            onClickBtnFn: (rawData) => history.push(`/pick-request/${location.pathname.split('/')[2]}/redraft/${rawData.id}`),
                        },
                        checkBeforeActionRenderFn: (rawData) => {
                            return rawData.rowData.status === "REJECTED";
                        }
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        isSetModel: true,
                        actionBtn: {
                            tooltip: "Send Request",
                            color: "success",
                            icon: "fas fa-share",
                            btnText: "send"
                        },
                        modalProps: {
                            modalTitle: "Please Confirm",
                            modalType: "",
                            modalBodyComponent: SendRequestUIMessage,
                            modalFooterComponent: SendRequestUIBtn,
                            isSetClose: true,
                            isSetFooterClose: true,
                            parentToModelProps: {
                                type: "datatable"
                            },
                            closeBtnName: "No"
                        },
                        checkBeforeActionRenderFn: (rawData) => {
                            return rawData.rowData.status === "DRAFT" && rawData.rowData.pickRequestCsvUrl === null;
                        }
                    },
                    {
                        actionType: actionBtnTypes.other,
                        isSetModel: true,
                        actionBtn: {
                            icon: "fas fa-print",
                            color: "primary",
                            tooltip: "Print",
                            btnText: "print"
                        },
                        modalProps: {
                            modalTitle: "Manifest",
                            modalType: modalTypes.large,
                            modalBodyComponent: ManifestPrintBody,
                            modalFooterComponent: PrintManifestBtn,
                            isSetClose: true,
                            isSetFooterClose: true,
                        }
                    }
                ]}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
})( withRouter(AllRequests));
