import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get, filter, find, uniqBy } from "lodash";
import { connect } from "react-redux";

import {
    InputButton,
    FormWrapper,
    DatePickerBoxWithState,
    SelectBoxWithState,
    RadioButtonWithState,
    InputBoxWithState
} from "../../../../components/boda-boda/Forms";
import { currency, deliveryTypes, weightMetric, packageSearchFilters, mapPackageStatusesNames } from "../../../../config/boda-boda/db_config/db_status.config";
import {
    isEmptyValue,
    fromObjectToQueryString,
    downloadCSV,
    roundValue,
    convertToCsvValue
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn, changeInputFn } from "../../../../store/boda-boda/form/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getMerchantBranchAllAPI, packageStatusReportDedicatedAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setPageLoader } from "../../../../store/boda-boda/ui/actions";
import { setApiResponse } from "../../../../store/actions";
import { setFlashMessage } from "../../../../store/boda-boda/ui/actions"

class PackageStatusReport extends Component {

    onGenerateFn = () => {
        let { formState, history } = this.props;
        if (this.validateFn() === true) {
            let createdDateTo = new Date(get(formState, "package_status_search.createdDateTo"));
            createdDateTo = new Date(createdDateTo.setDate(createdDateTo.getDate() + 1));
            createdDateTo = new Date(createdDateTo.setSeconds(createdDateTo.getSeconds() - 1));

            let deliveredDateTo = new Date(get(formState, "package_status_search.deliveredDateTo"));
            deliveredDateTo = new Date(deliveredDateTo.setDate(deliveredDateTo.getDate() + 1));
            deliveredDateTo = new Date(deliveredDateTo.setSeconds(deliveredDateTo.getSeconds() - 1));

            let returnedDateTo = new Date(get(formState, "package_status_search.returnedDateTo"));
            returnedDateTo = new Date(returnedDateTo.setDate(returnedDateTo.getDate() + 1));
            returnedDateTo = new Date(returnedDateTo.setSeconds(returnedDateTo.getSeconds() - 1));

            const queryObj = {
                createdDateFrom: get(formState, "package_status_search.createdDateFrom").toISOString(),
                createdDateTo: createdDateTo.toISOString(),
                deliveryType: get(formState, "package_status_search.deliveryType"),
                paymentMethod: get(formState, "package_status_search.paymentMethod"),
                status: get(formState, "package_status_search.status"),
                pickedUp: get(formState, "package_status_search.pickedUp"),
                returnedDateFrom: get(formState, "package_status_search.returnedDateFrom") ? get(formState, "package_status_search.returnedDateFrom").toISOString() : undefined,
                returnedDateTo: isEmptyValue(get(formState, "package_status_search.returnedDateTo")) ? undefined : returnedDateTo.toISOString(),
                deliveredDateFrom: get(formState, "package_status_search.deliveredDateFrom") ? get(formState, "package_status_search.deliveredDateFrom").toISOString() : undefined,
                deliveredDateTo: isEmptyValue(get(formState, "package_status_search.deliveredDateTo")) ? undefined : deliveredDateTo.toISOString(),
                merchantBranchId: get(formState, "package_status_search.merchantBranchId").split("/")[0],
            }

            if (get(formState, "package_status_search.type", "screen") === "screen") {
                history.push("/reports/package-status-report/view");
            } else {
                this.getAPIData(fromObjectToQueryString(queryObj));
            }
        }
    }

    validateFn = () => {
        let { formState, setFormErrorFn } = this.props;
        const errors = [];

        if (isEmptyValue(get(formState, "package_status_search.createdDateFrom", ""))) {
            errors.push({
                "property": "createdDateFrom",
                "error": "The Created Date From field is required"
            })
        }

        if (isEmptyValue(get(formState, "package_status_search.createdDateTo", ""))) {
            errors.push({
                "property": "createdDateTo",
                "error": "The Created Date To field is required"
            })
        }


        if (!isEmptyValue(get(formState, "package_status_search.deliveredDateFrom", "")) || !isEmptyValue(get(formState, "package_status_search.deliveredDateTo", ""))) {
            if (isEmptyValue(get(formState, "package_status_search.deliveredDateFrom", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "deliveredDateFrom",
                    "error": "Delivery date from is required if delivery date to is entered"
                }]);

                return false;
            }
            if (isEmptyValue(get(formState, "package_status_search.deliveredDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "deliveredDateTo",
                    "error": "Delivery date to is required if delivery date from is entered"
                }]);

                return false;
            }
            if (new Date(get(formState, "package_status_search.deliveredDateFrom", "")) > new Date(get(formState, "package_status_search.deliveredDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "deliveredDateTo",
                    "error": "The delivery date to field should not be less than the delivery date to From"
                }]);

                return false;
            }
        }

        if (!isEmptyValue(get(formState, "package_status_search.returnedDateFrom", "")) || !isEmptyValue(get(formState, "package_status_search.returnedDateTo", ""))) {
            if (isEmptyValue(get(formState, "package_status_search.returnedDateFrom", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "returnedDateTo",
                    "error": "Returned date from is required if returned date to is entered"
                }]);

                return false;
            }
            if (isEmptyValue(get(formState, "package_status_search.returnedDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "returnedDateTo",
                    "error": "Returned date to is required if returned date to is entered"
                }]);

                return false;
            }
            if (new Date(get(formState, "package_status_search.returnedDateFrom", "")) > new Date(get(formState, "package_status_search.returnedDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "returnedDateTo",
                    "error": "The returned date to To field should not be less than the returned date to From"
                }]);

                return false;
            }
        }

        if (errors.length === 0) {
            if (new Date(get(formState, "package_status_search.createdDateFrom", "")) > new Date(get(formState, "package_status_search.createdDateTo", ""))) {
                setFormErrorFn("package_status_search", [{
                    "property": "createdDateTo",
                    "error": "The Created Date To field should not be less than the Created Date From"
                }]);

                return false;
            } else {
                setFormErrorFn("package_status_search", []);
                return true;
            }
        } else {
            setFormErrorFn("package_status_search", errors);
            return false;
        }
    }

    getAPIData = (queryString) => {
        let { setPageLoader, setFlashMessage } = this.props;

        setPageLoader(true);

        callApi(`${packageStatusReportDedicatedAPI.url}?${queryString}&isJsonFile=true`)
            .method("get")
            .headers(true)
            .send()
            .then(async (result) => {
                if (get(result, "_statue", "") === false) {
                    if (get(result, "data.statusCode", 0) === 403) {
                        setFlashMessage({
                            status: true,
                            message: "Permission denied to access",
                            type: "danger",
                        });
                    } else {
                        setFlashMessage({
                            status: true,
                            message: "Something went wrong",
                            type: "danger",
                        });
                    }
                    setPageLoader(false);
                } else {
                    if (result?.data?.data?.isJsonFile) {
                        const response = await fetch(result?.data?.data?.url);

                        if (!response.ok) {
                            throw new Error('Wrong URL provided');
                        }

                        result.data = await response.json();
                    }
                    this.generateCSVData(get(result, "data.data", []), get(result, "data.meta.totalsRow", {}));
                    setPageLoader(false);
                }
            })
            .catch((error) => {
                setPageLoader(false);
            })
    }

    displayAttempts = (value) => {
        let finalString = '';
        let counter = 1;
        for (let index = 0; index < value?.packageHistories?.length; index++) {
            if (value?.packageHistories[index]?.status === "DELIVERED" || value?.packageHistories[index]?.status === "NOT_DELIVERED") {
                finalString += `${counter}) ${dateObjectToString(value?.packageHistories[index]?.updatedAt, "YY-mm-dd HH:MM:II")} - ${value?.packageHistories[index]?.driver?.name} - ${value?.packageHistories[index]?.status === "DELIVERED" ? "Delivered" : "Failed Delivery"}  `
                counter++;
            }
        }
        return finalString;
    }

    getAttemptedCount = (value) => {
        let counter = 0;
        for (let index = 0; index < value?.packageHistories?.length; index++) {
            if (value?.packageHistories[index]?.status === "DELIVERED" || value?.packageHistories[index]?.status === "NOT_DELIVERED") {
                counter++;
            }
        }
        return `${counter}`;
    };

    generateCSVData = (data = [], totalData) => {
        let { formState } = this.props;

        const reportName = ["Package Status Report "];
        const dateRange = [`Created From ${dateObjectToString(get(formState, "package_status_search.createdDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "package_status_search.createdDateTo"), "dd/mm/YY")}`];
        const deliveredDateRange = [`"Delivered From ${get(formState, "package_status_search.deliveredDateFrom") ? `${dateObjectToString(get(formState, "package_status_search.deliveredDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "package_status_search.deliveredDateTo"), "dd/mm/YY")}` : "All"}"`];
        const returnedDateRange = [`"Returned From ${get(formState, "package_status_search.returnedDateFrom") ? `${dateObjectToString(get(formState, "package_status_search.returnedDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "package_status_search.returnedDateTo"), "dd/mm/YY")}` : "All"}"`];
        const deliveryType = [`"Delivery Type: ${convertToCsvValue(get(formState, "package_status_search.deliveryType", "") === "" ? "All" : get(formState, "package_status_search.deliveryType", ""))}"`];
        const packageStatus = [`"Package Status: ${convertToCsvValue(get(formState, "package_status_search.status", "") === "" ? "All" : mapPackageStatusesNames[get(formState, "package_status_search.status", "")])}"`];
        const paymentType = [`"Payment Type: ${convertToCsvValue(get(formState, "package_status_search.paymentMethod", "") === "" ? "All" : get(formState, "package_status_search.paymentMethod", ""))}"`];
        const pickedUp = [`"Picked Up: ${convertToCsvValue(get(formState, "package_status_search.pickedUp", "") === "" ? "All" : get(formState, "package_status_search.pickedUp", "") === "true" ? "Yes" : "No")}"`];
        const merchantBranchId = [`"Merchant Branch: ${convertToCsvValue(get(formState, "package_status_search.merchantBranchId", "") === "" ? "All" : get(formState, "package_status_search.merchantBranchId", "").split("/")[1])}"`];
        const headers = ["Created Date", "Article ID", "Tracking Number", "Consignment ID", "Order Number", "Manifest Code", "Pickup Runsheet Code", "Customer Name", "Address", "City", "Customer Contact Number", "Zone", "Merchant Code", "Merchant Branch", "Delivery Type", "XL Package",
            "Payment Type", "Lodgement Location", "Last Mile Driver", "Destination Delivery Center", "Picked Up", "Pick Up Date", "Status", "Closure Date/Time", "Status Reason", "COD Status", "Merchant Settled Date", "Merchant Settlement Reference", "Merchant Settlement Remark", "Attempted Deliveries",
            "Total Attempts", "Current Location", `Actual Weight (${weightMetric})`, `Volumetric Weight (${weightMetric})`, `Order Amount (${currency})`, `Cash on Delivery Fee (${currency})`, `Card on Delivery Fee (${currency})`, `Express Fee (${currency})`, `XL Handling Fee (${currency})`,
            `Delivery Fee (${currency})`, `Delivery Flat Fee (${currency})`, `Total Delivery Fee (${currency})`, `Total Delivery Flat Fee (${currency})`,];
        const dataList = [];

        data.forEach((value) => {
            const temp = [];
            temp.push(value?.createdAt ? dateObjectToString(get(value, "createdAt", "")) : "-");
            temp.push(`"${convertToCsvValue(get(value, "articleNo", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.trackingNumber", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.consignmentNo", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.channelOrderNo", "-"))}"`);
            temp.push(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST").length !== 0 ? `"${convertToCsvValue(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0].manifest?.code)}"` : "-");
            temp.push(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST").length !== 0 ? `"${convertToCsvValue(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0].manifest?.runSheetLast?.code)}"` : "-");
            temp.push(`"${convertToCsvValue(get(value, "order.name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.shippingLocation.address", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.shippingLocation.city.name", "-"))}"`);
            temp.push(get(value, "order.phone", "-"));
            temp.push(`"${convertToCsvValue(get(value, "order.shippingLocation.city.zone.name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "merchant.code", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "merchantBranch.name", "-"))}"`);
            temp.push(get(value, "deliveryType", "-"));
            temp.push(get(value, "xlHandling", false) === false ? "No" : "Yes");
            temp.push(get(value, "order.paymentMethod", "-") === "CASH_ON_DELIVERY" ? "CASH ON DELIVERY" : get(value, "order.paymentMethod", "-") === "CARD_ON_DELIVERY" ? "CARD ON DELIVERY" : get(value, "order.paymentMethod", "-") === "PREPAID" ? "PREPAID" : "-");
            temp.push(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST").length !== 0 ? `"${convertToCsvValue(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0].manifest.locationDrop.name)}"` : "-");
            temp.push(`"${convertToCsvValue(get(value, "courier.staffs[0].name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "locationFinalFacility.name", "-"))}"`);
            temp.push(get(value, "tookOverAt", false) ? "Yes" : "No");
            temp.push(value?.tookOverAt ? dateObjectToString(get(value, "tookOverAt", "")) : "-");
            temp.push(
                get(value, "status", "-") === "PACKED" ?
                    (value.onHold === true) ? "Packed" + "(On Hold)" : "Packed"
                    : get(value, "status", "-") === "IN_TRANSIT_FIRST" ?
                        (value.onHold === true) ? "Package Picked up" + "(On Hold)" : "Package Picked up"

                        : get(value, "status", "-") === "RECEIVED" ?
                            (value.onHold === true) ? "Received at processing Center" + "(On Hold)" : "Received at processing Center"

                            : get(value, "status", "-") === "SORTED" ?
                                (value.onHold === true) ? "Ready for Transit" + "(On Hold)" : "Ready for Transit"

                                : get(value, "status", "-") === "IN_TRANSIT_LINE" ?
                                    (value.onHold === true) ? "Intransit to regional hub" + "(On Hold)" : "Intransit to regional hub"

                                    : get(value, "status", "-") === "IN_TRANSIT_LAST" ?
                                        (value.onHold === true) ? "Out for Delivery" + "(On Hold)" : "Out for Delivery"

                                        : get(value, "status", "-") === "DELIVERED" ?
                                            (value.onHold === true) ? "Delivered" + "(On Hold)" : "Delivered"

                                            : get(value, "status", "-") === "NOT_DELIVERED" ?
                                                (value.onHold === true) ? "Failed Delivery" + "(On Hold)" : "Failed Delivery"
                                                : get(value, "status", "-") === "READY_FOR_RETURN" ?
                                                    (value.onHold === true) ? "Ready for Return" + "(On Hold)" : "Ready for Return"
                                                    : get(value, "status", "-") === "IN_TRANSIT_RETURN" ?
                                                        (value.onHold === true) ? "Return Intransit" + "(On Hold)" : "Return Intransit"
                                                        : get(value, "status", "-") === "RETURNED_TO_MERCHANT" ?
                                                            (value.onHold === true) ? "Returned to Merchant" + "(On Hold)" : "Returned to Merchant"
                                                            : get(value, "status", "-") === "NOT_RETURNED" ?
                                                                (value.onHold === true) ? "Failed Return" + "(On Hold)" : "Failed Return"
                                                                : "-");
            temp.push(
                get(value, "status", "-") === "DELIVERED" ?
                    (get(value, "deliveredAt", "-") !== null) ? dateObjectToString(get(value, "deliveredAt", new Date()), "YY-mm-dd HH:MM:II") : "-"
                    : get(value, "status", "-") === "RETURNED_TO_MERCHANT" ?
                        (get(value, "returnedAt", "-") !== null) ? dateObjectToString(get(value, "returnedAt", new Date()), "YY-mm-dd HH:MM:II") : "-"
                        : "-");
            temp.push(
                get(value, "status", "-") === "DELIVERED" ?
                    `"${convertToCsvValue(value.order.remarkDelivery)}"`
                    : get(value, "status", "-") === "NOT_DELIVERED" || get(value, "status", "-") === "READY_FOR_RETURN" || get(value, "status", "-") === "RETURNED_TO_MERCHANT" ?
                        `"${convertToCsvValue(value.notDeliveredReason)}"`
                        : "-");
            temp.push(`"${convertToCsvValue(get(value, "order.paymentMethod") === "CASH_ON_DELIVERY" ? get(value, "order.codSettlementStatus", "-") : "-")}"`);
            temp.push(value?.order?.headOfficeSettlement?.settledAt ? dateObjectToString(get(value, "order.headOfficeSettlement.settledAt", "-")) : "-");
            temp.push(`"${convertToCsvValue(get(value, "order.headOfficeSettlement.reference", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.headOfficeSettlement.remark", "-"))}"`);
            temp.push(`"${convertToCsvValue(this.displayAttempts(value))}"`);
            temp.push(`"${convertToCsvValue(this.getAttemptedCount(value))}"`);
            temp.push(get(value, "locationCurrent.name", "-"));
            temp.push(get(value, "weight", "-"));
            temp.push(get(value, "volumetric", "-"));
            temp.push(isEmptyValue(get(value, "order.amount", "")) ? "-" : get(value, "order.amount", ""));
            temp.push(get(value, "order.paymentMethod", "") === "CASH_ON_DELIVERY" ? get(value, "order.cashDeliveryPrice", "-") : "0.00");
            temp.push(get(value, "order.paymentMethod", "") === "CARD_ON_DELIVERY" ? get(value, "order.cardDeliveryPrice", "-") : "0.00");
            temp.push(get(value, "expressFee", "-"));
            temp.push(get(value, "xlPackageHandlingFee", "-"));
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? get(value, "merchantPrice", "0") : "0");
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? "0" : get(value, "merchantFlatPrice", "0"));
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? get(value, "totalPrice", "0") : "0");
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? "0" : get(value, "totalFlatPrice", "0"));
            dataList.push(temp);
        });

        const finalDataSet = [reportName, [""], dateRange, deliveredDateRange, returnedDateRange, deliveryType, paymentType, pickedUp, packageStatus, merchantBranchId, [""], [""], headers];

        dataList.forEach((value) => {
            finalDataSet.push(value);
        });

        finalDataSet.push([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Total",
            roundValue(get(totalData, "expressFee", "")),
            roundValue(get(totalData, "xlPackageHandlingFee", "")),
            roundValue(get(totalData, "merchantPrice", "")),
            roundValue(get(totalData, "merchantFlatPrice", "")),
            roundValue(get(totalData, "totalPrice", "")),
            roundValue(get(totalData, "totalFlatPrice", ""))
        ]);

        const csvDataContent = finalDataSet.map(e => e.join(",")).join("\n");
        const fileName = `Package Status Report-${Date.now()}.csv`;
        downloadCSV(csvDataContent, fileName);
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Package Status Report</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Package Status Report</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className={"card"} style={{ marginBottom: "120px" }}>
                                <div className={"card-body"}>

                                    <FormWrapper
                                        setGroupName={"package_status_search"}
                                        onDestroyUnsetFormObject={false}
                                        setFormObject={{
                                            createdDateFrom: null,
                                            createdDateTo: null,
                                            deliveryType: "",
                                            status: "",
                                            type: "screen",
                                            paymentMethod: "",
                                            pickedUp: "",
                                            returnedDateFrom: null,
                                            returnedDateTo: null,
                                            deliveredDateFrom: null,
                                            deliveredDateTo: null,
                                            merchantBranchId: "",
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Created Date From"
                                                    inputName={"createdDateFrom"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Created Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Created Date To"
                                                    inputName={"createdDateTo"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Created Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Delivered Date From"
                                                    inputName={"deliveredDateFrom"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Delivered Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Delivered Date To"
                                                    inputName={"deliveredDateTo"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Delivered Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Returned Date From"
                                                    inputName={"returnedDateFrom"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Returned Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Returned Date To"
                                                    inputName={"returnedDateTo"}
                                                    formGroupName={"package_status_search"}
                                                    placeholderText={"Returned Date To"}
                                                />
                                            </div>


                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Payment Type"}
                                                    inputName={"paymentMethod"}
                                                    placeHolderText={"Payment Type"}
                                                    formGroupName={"package_status_search"}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        {
                                                            id: "PREPAID",
                                                            value: "PREPAID",
                                                        },
                                                        {
                                                            id: "CASH_ON_DELIVERY",
                                                            value: "CASH ON DELIVERY",
                                                        },
                                                        {
                                                            id: "CARD_ON_DELIVERY",
                                                            value: "CARD ON DELIVERY",
                                                        },
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Delivery Type"}
                                                    inputName={"deliveryType"}
                                                    placeHolderText={"Delivery Type"}
                                                    formGroupName={"package_status_search"}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        ...deliveryTypes
                                                    ]}
                                                />
                                            </div>


                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Status"}
                                                    inputName={"status"}
                                                    placeHolderText={"Status"}
                                                    formGroupName={"package_status_search"}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        ...packageSearchFilters
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Picked Up"}
                                                    inputName={"pickedUp"}
                                                    placeHolderText={"Picked Up"}
                                                    formGroupName={"package_status_search"}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        {
                                                            id: "true",
                                                            value: "Yes"
                                                        },
                                                        {
                                                            id: "false",
                                                            value: "No"
                                                        }
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                {get(this.props, `authState.authUser.merchant.id`) ?
                                                    <SelectBoxWithState
                                                        labelText={"Merchant Branch"}
                                                        placeHolderText={"Merchant Branch"}
                                                        inputName={"merchantBranchId"}
                                                        formGroupName={"package_status_search"}
                                                        apiUrl={`${getMerchantBranchAllAPI.url}?merchantId=${get(this.props, `authState.authUser.merchant.id`)}`}
                                                        apiStateKey={`${getMerchantBranchAllAPI.key}`}
                                                        onRebuildResponseFn={(response) => {
                                                            const data = [{
                                                                id: "",
                                                                value: "All"
                                                            }];

                                                            response.forEach((value) => {
                                                                data.push({
                                                                    id: `${value.id}/${value.name}`,
                                                                    value: value.name
                                                                });
                                                            });

                                                            return {
                                                                "data": {
                                                                    "data": data
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>


                                            <div className="col-md-6">
                                                <RadioButtonWithState
                                                    isRequired={true}
                                                    labelText={"Report Type"}
                                                    inputName={"type"}
                                                    formGroupName={"package_status_search"}
                                                    dataList={[
                                                        {
                                                            id: "screen",
                                                            value: "View on Screen"
                                                        },
                                                        {
                                                            id: "csv",
                                                            value: "Export"
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>

                                                <InputButton
                                                    btnText={"Generate"}
                                                    startIcon={"fas fa-spinner"}
                                                    onClickBtnFn={this.onGenerateFn}
                                                />

                                            </div>
                                        </div>
                                    </FormWrapper>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
    setPageLoader,
    changeInputFn,
    setApiResponse,
    setFlashMessage,
})(PackageStatusReport);
