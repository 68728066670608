import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { findIndex, get } from "lodash";

import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { FormWrapper } from "../../../../../components/boda-boda/Forms";
import { getPackageListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import {
    currency,
    lengthMetric,
    mapOrderPaymentMethods,
    mapPackageStatusesNames,
    weightMetric
} from "../../../../../config/boda-boda/db_config/db_status.config";
import { roundValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { roundUpTypes } from "../../../../../config/boda-boda/core.config";


class ViewPackage extends Component {
    getPackageNo = (data = [], id) => {
        const index = findIndex(data, { id: parseInt(id) });

        if (index !== -1) {
            return `${index + 1}/${data.length}`;
        } else {
            return "";
        }
    }

    render() {
        let { formState, row } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getPackageListAPI.key}_view`}
                    apiUrl={`${getPackageListAPI.url}/${row.id}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            articleNo: response.articleNo,
                            type: response.type,
                            code: response.code,
                            category: response.category,
                            weight: response.weight,
                            height: response.height,
                            width: response.width,
                            length: response.length,
                            status: response.status,
                            deletedAt: response.deletedAt,
                            deliveryType: response.deliveryType,
                            order: response.order,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            expressFee: response.expressFee,
                            totalPrice: response.totalPrice,
                            cashDeliveryAmount: response.cashDeliveryAmount,
                            cardDeliveryAmount: response.cardDeliveryAmount,
                            xlPackageHandlingFee: response.xlPackageHandlingFee,
                            merchantPrice: response.merchantPrice,
                            volumetric: response.volumetric,
                            metric: response.metric,
                            totalFlatPrice: response.totalFlatPrice,
                            merchantFlatPrice: response.merchantFlatPrice,
                            priceType: response.priceType,
                            notDeliveredReason: response.notDeliveredReason,
                            contract: {
                                chargingMethod: response?.contract?.chargingMethod,
                            },
                            pickUp: get(response, "locationPick.name", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className="row">
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Package Details</h4>

                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                        <dd className={"col-sm-4"}>Article No</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.articleNo`, "")}</dt>

                                        <dd className={"col-sm-4"}>Order No</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.channelOrderNo`, "")}</dt>

                                        <dd className={"col-sm-4"}>Consignment No</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.consignmentNo`, "")}</dt>

                                        <dd className={"col-sm-4"}>Package No</dd>
                                        <dt className={"col-sm-8"}>{this.getPackageNo(get(formState, `${getPackageListAPI.key}_view.order.packages`, []), row.id)}</dt>

                                        <dd className={"col-sm-4"}>Signature Required</dd>
                                        <dt className={"col-sm-8"}>
                                            {
                                                get(formState, `${getPackageListAPI.key}_view.order.signatureRequired`, false) === false ? "No" : "Yes"
                                            }
                                        </dt>

                                        <dd className={"col-sm-4"}>Package Type</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.type.name`, "")}</dt>

                                        <dd className={"col-sm-4"}>Package Category</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.category.name`, "")}</dt>

                                        {
                                            (get(formState, `${getPackageListAPI.key}_view.order.paymentMethod`, "") !== "PREPAID") ? (
                                                <Fragment>
                                                    <dd className={"col-sm-4"}>Order Amount</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.amount`, "")} {currency}</dt>
                                                </Fragment>
                                            ) : null
                                        }

                                        <dd className={"col-sm-4"}>Pick Up Location</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.pickUp`, "")}</dt>

                                        <dd className={"col-sm-4"}>Payment Method</dd>
                                        <dt className={"col-sm-8"}>{mapOrderPaymentMethods[get(formState, `${getPackageListAPI.key}_view.order.paymentMethod`, "")]}</dt>
                                    </dl>
                                </div>

                                <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                        <dd className={"col-sm-4"}>Weight</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.weight`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.weight`, "")} ${weightMetric}` : "N/A"}</dt>

                                        <dd className={"col-sm-4"}>Length</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.length`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.length`, "")} ${lengthMetric}` : "N/A"}</dt>

                                        <dd className={"col-sm-4"}>Width</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.width`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.width`, "")} ${lengthMetric}` : "N/A"}</dt>

                                        <dd className={"col-sm-4"}>Height</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.height`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.height`, "")} ${lengthMetric}` : "N/A"}</dt>

                                        {(get(formState, `${getPackageListAPI.key}_view.contract.chargingMethod`, "") === "VOLUMETRIC") ?
                                            <React.Fragment>
                                                <dd className={"col-sm-4"}>Volumetric Weight</dd>
                                                <dt className={"col-sm-8"}>{roundValue(get(formState, `${getPackageListAPI.key}_view.volumetric`, ""))}</dt>
                                            </React.Fragment>
                                            : null
                                        }


                                        <dd className={"col-sm-4"}>Delivery Type</dd>
                                        <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.deliveryType`, "")}</dt>

                                        <dd className={"col-sm-4"}>Status</dd>
                                        <dt className={"col-sm-8"}>{mapPackageStatusesNames[get(formState, `${getPackageListAPI.key}_view.status`, "")]}</dt>

                                        {
                                            get(formState, `${getPackageListAPI.key}_view.status`) === "NOT_DELIVERED" ? (
                                                <Fragment>
                                                    <dd className={"col-sm-4"}>Reason</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.notDeliveredReason`, "")}</dt>
                                                </Fragment>
                                            ) : null
                                        }

                                        {get(formState, `${getPackageListAPI.key}_view.deletedAt`) !== null ? (
                                            <Fragment>
                                                <dd className={"col-sm-4"}>Deleted At</dd>
                                                <dt className={"col-sm-8"}>{dateObjectToString(
                                                    get(formState, `${getPackageListAPI.key}_view.deletedAt`, ""),
                                                    "YY-mm-dd HH:MM:II"
                                                )
                                                }</dt>
                                            </Fragment>

                                        ) : null

                                        }

                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Receiver Details</h4>

                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                        <dd className={"col-sm-5"}>Full Name</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.name`, "")}</dt>

                                        <dd className={"col-sm-5"}>Email</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.email`, "")}</dt>

                                        <dd className={"col-sm-5"}>Phone</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.phone`, "")}</dt>

                                        {
                                            (get(formState, `${getPackageListAPI.key}_view.status`, "") === "DELIVERED") ? (
                                                <Fragment>
                                                    <dd className={"col-sm-5"}>Reciever Name</dd>
                                                    <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.remarkDelivery`, "-")}</dt>
                                                </Fragment>
                                            ) : null
                                        }
                                    </dl>
                                </div>

                                <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                        <dd className={"col-sm-5"}>ID Verification Required</dd>
                                        <dt className={"col-sm-7"}>
                                            {
                                                get(formState, `${getPackageListAPI.key}_view.order.idVerificationRequired`, false) === false ? "No" : "Yes"
                                            }
                                        </dt>

                                        {
                                            get(formState, `${getPackageListAPI.key}_view.order.idVerificationRequired`, false) === true ? (
                                                <Fragment>
                                                    {
                                                        (get(formState, `${getPackageListAPI.key}_view.order.idVerificationType`, null) !== null && get(formState, `${getPackageListAPI.key}_view.order.idVerificationReference`, null) !== null) ? (
                                                            <Fragment>
                                                                <dd className={"col-sm-5"}>ID Verification Type</dd>
                                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.idVerificationType`, "")}</dt>
                                                            </Fragment>
                                                        ) : null
                                                    }

                                                    {
                                                        get(formState, `${getPackageListAPI.key}_view.order.idVerificationReference`, null) !== null ? (
                                                            <Fragment>
                                                                <dd className={"col-sm-5"}>ID Verification Reference</dd>
                                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.idVerificationReference`, "")}</dt>
                                                            </Fragment>
                                                        ) : null
                                                    }
                                                </Fragment>
                                            ) : null
                                        }
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Shipping Location</h4>

                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                        <dd className={"col-sm-5"}>Location Name</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.name`, "")}</dt>

                                        <dd className={"col-sm-5"}>Address</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.address`, "")}</dt>

                                        <dd className={"col-sm-5"}>Postal Code</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.postalCode`, "")}</dt>

                                        <dd className={"col-sm-5"}>Plus Code</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.plusCode`, "")}</dt>

                                        <dd className={"col-sm-5"}>Latitude</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.lat`, "")}</dt>
                                    </dl>
                                </div>

                                <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                        <dd className={"col-sm-5"}>Longitude</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.lon`, "")}</dt>

                                        <dd className={"col-sm-5"}>Province</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.district.province.name`, "")}</dt>

                                        <dd className={"col-sm-5"}>District</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.district.name`, "")}</dt>

                                        <dd className={"col-sm-5"}>City</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.name`, "")}</dt>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Delivery Charges</h4>

                            {
                                (get(formState, `${getPackageListAPI.key}_view.status`, "") === "PACKED") ?
                                    (<React.Fragment><h8>Delivery charges might vary after updating the package dimensions or when it is picked up.</h8><br /><br /></React.Fragment>) : (null)
                            }

                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                        <dd className={"col-sm-4"}>Rate Category</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.category.name`, "")}</dt>

                                        <dd className={"col-sm-4"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, "") === "VL_RATE" ? "Delivery Charge" : "Delivery Flat charge"}</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, "") === "VL_RATE" ? `${roundValue(get(formState, `${getPackageListAPI.key}_view.merchantPrice`, "0"))} ${currency}` : `${roundValue(get(formState, `${getPackageListAPI.key}_view.merchantFlatPrice`, "0"))} ${currency}`}</dt>

                                        <dd className={"col-sm-4"}>Express Delivery Charge</dd>
                                        <dt className={"col-sm-7"}>{`${roundValue(get(formState, `${getPackageListAPI.key}_view.expressFee`, "0"), 2, roundUpTypes.round)} ${currency}`}</dt>
                                    </dl>
                                </div>

                                <div className={"col-md-6"}>
                                    <dl className={"row"}>
                                        <dd className={"col-sm-4"}>XL Handling Charge</dd>
                                        <dt className={"col-sm-7"}>{`${roundValue(get(formState, `${getPackageListAPI.key}_view.xlPackageHandlingFee`, "0"))} ${currency}`}</dt>

                                        <dd className={"col-sm-4"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, "") === "VL_RATE" ? "Total Charge" : "Total Flat Charge"}</dd>
                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, "") === "VL_RATE" ? `${roundValue(get(formState, `${getPackageListAPI.key}_view.totalPrice`, "0"))} ${currency}` : `${roundValue(get(formState, `${getPackageListAPI.key}_view.totalFlatPrice`, "0"))} ${currency}`}</dt>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <h4 className="card-title mb-3">Notes</h4>

                            <dl className={"row"}>
                                <dd className={"col-sm-2"}>Public Note</dd>
                                <dt className={"col-sm-10"}>
                                    {
                                        get(formState, `${getPackageListAPI.key}_view.notesPublic`, null) !== null ? get(formState, `${getPackageListAPI.key}_view.notesPublic`, null) : "-"
                                    }
                                </dt>

                                <dd className={"col-sm-2"}>Private Note</dd>
                                <dt className={"col-sm-10"}>
                                    {
                                        get(formState, `${getPackageListAPI.key}_view.notesPrivate`, null) !== null ? get(formState, `${getPackageListAPI.key}_view.notesPrivate`, null) : "-"
                                    }
                                </dt>
                            </dl>
                        </div>
                    </div>

                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(ViewPackage);
