import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { reconciliationReportDedicatedAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { currency, mapPackageStatusesNames } from "../../../../../config/boda-boda/db_config/db_status.config";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { fromObjectToQueryString, isEmptyValue, roundValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";


const TableLastRow = (props) => {
    return (
        <Fragment>
            <tr>
                <td colSpan={"23"}></td>
                <td>Total</td>
                <td>{get(props, "tableBodyLastProps.expressFee", "")}</td>
                <td>{get(props, "tableBodyLastProps.xlPackageHandlingFee", "")}</td>
                <td>{get(props, "tableBodyLastProps.merchantPrice", "")}</td>
                <td>{get(props, "tableBodyLastProps.merchantFlatPrice", "")}</td>
                <td>{get(props, "tableBodyLastProps.totalPrice", "")}</td>
                <td>{get(props, "tableBodyLastProps.totalFlatPrice", "")}</td>
            </tr>
        </Fragment>
    )
}

class ReconciliationReportView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loaderStatus: false
        }
    }

    getQueryString = () => {
        let { formState } = this.props;

        let dateTo = new Date(get(formState, "reconciliation_search.dateTo", new Date()));
        dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
        dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

        let deliveredDateTo = new Date(get(formState, "reconciliation_search.deliveredDateTo"));
        deliveredDateTo = new Date(deliveredDateTo.setDate(deliveredDateTo.getDate() + 1));
        deliveredDateTo = new Date(deliveredDateTo.setSeconds(deliveredDateTo.getSeconds() - 1));

        let failedDeliveryDateTo = new Date(get(formState, "reconciliation_search.failedDeliveryDateTo"));
        failedDeliveryDateTo = new Date(failedDeliveryDateTo.setDate(failedDeliveryDateTo.getDate() + 1));
        failedDeliveryDateTo = new Date(failedDeliveryDateTo.setSeconds(failedDeliveryDateTo.getSeconds() - 1));

        const queryObj = {
            dateFrom: get(formState, "reconciliation_search.dateFrom", new Date()).toISOString(),
            dateTo: dateTo.toISOString(),
            deliveryType: get(formState, "reconciliation_search.deliveryType", ""),
            status: get(formState, "reconciliation_search.status", ""),
            deliveredDateFrom: get(formState, "reconciliation_search.deliveredDateFrom", new Date()) ? get(formState, "reconciliation_search.deliveredDateFrom", new Date()).toISOString() : undefined,
            deliveredDateTo: get(formState, "reconciliation_search.deliveredDateTo") ? deliveredDateTo.toISOString() : undefined,
            failedDeliveryDateFrom: get(formState, "reconciliation_search.failedDeliveryDateFrom") ? get(formState, "reconciliation_search.failedDeliveryDateFrom").toISOString() : undefined,
            failedDeliveryDateTo: isEmptyValue(get(formState, "reconciliation_search.failedDeliveryDateTo")) ? undefined : failedDeliveryDateTo.toISOString(),
            codSettlementStatus: get(formState, "reconciliation_search.codSettlementStatus"),
            locationFinalFacilityId: get(formState, "reconciliation_search.locationFinalFacilityId")?.split("/")[0],
        }

        return `&${fromObjectToQueryString(queryObj)}`;
    }

    render() {
        let { formState, authState, coreState } = this.props;

        return (
            <React.Fragment>
                {
                    (get(formState, "reconciliation_search.dateFrom", "") === "" || get(formState, "reconciliation_search.dateTo", "") === "") ? (
                        <Redirect to={"/reports/lodgement-reconciliation-report"} />
                    ) : (
                        <div className="container-fluid">
                            <Row className="align-items-center">
                                <Col sm={6}>
                                    <div className="page-title-box">
                                        <h4 className="font-size-18">Lodgement Reconciliation Report</h4>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-sm-12"}>
                                            From<strong className={"mx-2"}>{dateObjectToString(get(formState, "reconciliation_search.dateFrom"), "dd/mm/YY")}</strong>to<strong className={"mx-2"}>{dateObjectToString(get(formState, "reconciliation_search.dateTo"), "dd/mm/YY")}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Delivered From:<strong className={"mx-2"}>{get(formState, "reconciliation_search.deliveredDateFrom") ? `${dateObjectToString(get(formState, "reconciliation_search.deliveredDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "reconciliation_search.deliveredDateTo"), "dd/mm/YY")}` : "All"}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Failed Delivery From:<strong className={"mx-2"}>{get(formState, "reconciliation_search.failedDeliveryDateFrom") ? `${dateObjectToString(get(formState, "reconciliation_search.failedDeliveryDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "reconciliation_search.failedDeliveryDateTo"), "dd/mm/YY")}` : "All"}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Merchant:<strong className={"mx-2"}>{get(authState, "authUser.merchant.name", "")}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Delivery Type:<strong className={"mx-2"}>{get(formState, "reconciliation_search.deliveryType", "") === "" ? "All" : get(formState, "reconciliation_search.deliveryType", "")}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Package Status:<strong className={"mx-2"}>{get(formState, "reconciliation_search.status", "") === "" ? "All" : mapPackageStatusesNames[get(formState, "reconciliation_search.status", "")]}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Branch:<strong className={"mx-2"}>{get(formState, "reconciliation_search.locationFinalFacilityId", "") === "" ? "All" : get(formState, "reconciliation_search.locationFinalFacilityId", "").split("/")[1]}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            COD Status:<strong className={"mx-2"}>{get(formState, "reconciliation_search.codSettlementStatus", "") === "" ? "All" : get(formState, "reconciliation_search.codSettlementStatus", "")}</strong>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={6}>
                                    <div className={"d-flex justify-content-end"}>
                                        <BackBtnComponent
                                            path={"/reports/lodgement-reconciliation-report"}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className={"row mt-3"}>
                                <CrudComponent
                                    title={"Lodgement Reconciliation Report"}
                                    apiURL={`${reconciliationReportDedicatedAPI.url}?page=1&limit=20${this.getQueryString()}`}
                                    dataTableStateKey={reconciliationReportDedicatedAPI.key}
                                    isFilter={false}
                                    isSetCreateAction={false}
                                    isDropdownActionBtn={false}
                                    isSetAction={false}
                                    tableHeaderList={[
                                        {
                                            displayName: "Pick Up Date", key: "packageHistories[0].manifest.pickedAt",
                                            onPrintFn: (rawData) => {
                                                return dateObjectToString(
                                                    rawData?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0].manifest.pickedAt
                                                    , "YY-mm-dd")
                                            }
                                        },
                                        { displayName: "Article ID", key: "articleNo" },
                                        { displayName: "Channel Order Number", key: "order.channelOrderNo" },
                                        { displayName: "Tracking Number", key: "order.trackingNumber" },
                                        { displayName: "Consignment ID", key: "order.consignmentNo" },
                                        {
                                            displayName: "Manifest Code", key: "packageHistories[0].manifest.code", default: "-",
                                            onPrintFn: (rawData) => {
                                                return rawData?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0].manifest.code
                                            },
                                        },
                                        { displayName: "Merchant Code", key: "merchant.code" },
                                        { displayName: "Delivery Type", key: "deliveryType" },
                                        { displayName: "Customer Name", key: "order.name" },
                                        { displayName: "Address", key: "order.shippingLocation.address" },
                                        { displayName: "City", key: "order.shippingLocation.city.name" },
                                        {
                                            displayName: "XL Package", key: "xlHandling", onPrintFn: (rawData) => {
                                                return rawData.xlHandling === false ? "No" : "Yes";
                                            }
                                        },
                                        { displayName: "Payment Type", key: "order.paymentMethod" },
                                        {
                                            displayName: "Status", key: "status", onPrintFn: (rawData) => {

                                                if (rawData.status === "PACKED") {
                                                    if (rawData.onHold === true) {
                                                        return "Packed" + "(On Hold)";
                                                    } else
                                                        return "Packed";

                                                } else if (rawData.status === "IN_TRANSIT_FIRST") {
                                                    if (rawData.onHold === true) {
                                                        return "Package Picked up" + "(On Hold)";
                                                    } else
                                                        return "Package Picked up";

                                                } else if (rawData.status === "RECEIVED") {
                                                    if (rawData.onHold === true) {
                                                        return "Received at processing Center" + "(On Hold)";
                                                    } else
                                                        return "Received at processing Center";

                                                } else if (rawData.status === "SORTED") {
                                                    if (rawData.onHold === true) {
                                                        return "Ready for Transit" + "(On Hold)";
                                                    } else
                                                        return "Ready for Transit";

                                                } else if (rawData.status === "IN_TRANSIT_LINE") {
                                                    if (rawData.onHold === true) {
                                                        return "Intransit to regional hub" + "(On Hold)";
                                                    } else
                                                        return "Intransit to regional hub";

                                                } else if (rawData.status === "IN_TRANSIT_LAST") {
                                                    if (rawData.onHold === true) {
                                                        return "Out for Delivery" + "(On Hold)";
                                                    } else
                                                        return "Out for Delivery";

                                                } else if (rawData.status === "DELIVERED") {
                                                    if (rawData.onHold === true) {
                                                        return "Delivered" + "(On Hold)";
                                                    } else
                                                        return "Delivered";

                                                } else if (rawData.status === "NOT_DELIVERED") {
                                                    if (rawData.onHold === true) {
                                                        return "Failed Delivery" + "(On Hold)";
                                                    } else
                                                        return "Failed Delivery";
                                                } else if (rawData.status === "READY_FOR_RETURN") {
                                                    if (rawData.onHold === true) {
                                                        return "Ready for Return" + "(On Hold)";
                                                    } else
                                                        return "Ready for Return";
                                                } else if (rawData.status === "IN_TRANSIT_RETURN") {
                                                    if (rawData.onHold === true) {
                                                        return "Return Intransit" + "(On Hold)";
                                                    } else
                                                        return "Return Intransit";
                                                } else if (rawData.status === "NOT_RETURNED") {
                                                    if (rawData.onHold === true) {
                                                        return "Failed Return" + "(On Hold)";
                                                    } else
                                                        return "Failed Return";
                                                } else if (rawData.status === "RETURNED_TO_MERCHANT") {
                                                    if (rawData.onHold === true) {
                                                        return "Returned to Merchant" + "(On Hold)";
                                                    } else
                                                        return "Returned to Merchant";
                                                }
                                            }
                                        },
                                        {
                                            displayName: "Closure Date/Time", key: "deliveredAt", onPrintFn: (rawData) => {

                                                if (rawData.status === "DELIVERED") {
                                                    return (get(rawData, "deliveredAt", "-") !== null) ? dateObjectToString(get(rawData, "deliveredAt", new Date()), "YY-mm-dd HH:MM:II") : "-";
                                                } else if (rawData.status === "RETURNED_TO_MERCHANT") {
                                                    return (get(rawData, "returnedAt", "-") !== null) ? dateObjectToString(get(rawData, "returnedAt", new Date()), "YY-mm-dd HH:MM:II") : "-";
                                                } else {
                                                    return "-";
                                                }
                                            }
                                        },
                                        {
                                            displayName: "Attempted Deliveries", key: "packageHistories[0].status", onPrintFn: (rawData) => {
                                                let counter = 1;
                                                return (
                                                    <React.Fragment>
                                                        {rawData?.packageHistories?.map((packageHistory, index) => {
                                                            if (packageHistory?.status === "DELIVERED" || packageHistory?.status === "NOT_DELIVERED") {
                                                                counter++;
                                                                return (
                                                                    <text>
                                                                        {`${counter - 1}) ${dateObjectToString(rawData?.packageHistories[index]?.updatedAt, "YY-mm-dd HH:MM:II")} - ${rawData?.packageHistories[index]?.driver?.name} - ${rawData?.packageHistories[index]?.status === "DELIVERED" ? "Delivered" : "Failed Delivery"}`} <br />
                                                                    </text>
                                                                )
                                                            }
                                                        })}
                                                    </React.Fragment>
                                                )
                                            }
                                        },
                                        {
                                            displayName: "Status Reason", key: "status", onPrintFn: (rawData) => {

                                                if (rawData.status === "DELIVERED") {
                                                    return get(rawData, "order.remarkDelivery", "-");
                                                } else if (rawData.status === "NOT_DELIVERED" || rawData.status === "READY_FOR_RETURN" || rawData.status === "RETURNED_TO_MERCHANT") {
                                                    return get(rawData, "notDeliveredReason", "-")
                                                }
                                            }
                                        },
                                        {
                                            displayName: "COD Status", key: "order.codSettlementStatus",
                                            onPrintFn: (rawData) => {
                                                if (rawData?.order?.paymentMethod === "CASH_ON_DELIVERY") {
                                                    return rawData?.order?.codSettlementStatus
                                                } else {
                                                    return "-"
                                                }
                                            }
                                        },
                                        {
                                            displayName: "Merchant Settled Date", key: "order.headOfficeSettlement.settledAt",
                                            onPrintFn: (rawData) => {
                                                if (rawData?.order?.headOfficeSettlement?.settledAt) {
                                                    return dateObjectToString(rawData?.order.headOfficeSettlement.settledAt)
                                                } else {
                                                    return ""
                                                }
                                            }
                                        },
                                        { displayName: "Merchant Settlement Reference", key: "order.headOfficeSettlement.reference" },
                                        { displayName: "Merchant Settlement Remark", key: "order.headOfficeSettlement.remark" },
                                        {
                                            displayName: `Order Amount (${currency})`, key: "order.amount", onPrintFn: (rawData) => {
                                                return isEmptyValue(rawData?.order.amount) ? "-" : rawData?.order.amount;
                                            }
                                        },
                                        {
                                            displayName: `Cash on Delivery Fee (${currency})`, key: "order.cashDeliveryPrice", onPrintFn: (rawData) => {
                                                return get(rawData, "order.paymentMethod", "") === "CASH_ON_DELIVERY" ? get(rawData, "order.cashDeliveryPrice", "-") : "0.00";
                                            }
                                        },
                                        {
                                            displayName: `Card on Delivery Fee (${currency})`, key: "order.cardDeliveryPrice", onPrintFn: (rawData) => {
                                                return get(rawData, "order.paymentMethod", "") === "CARD_ON_DELIVERY" ? get(rawData, "order.cardDeliveryPrice", "-") : "0.00";
                                            }
                                        },
                                        {
                                            displayName: `Express Fee (${currency})`, key: "expressFee", onPrintFn: (rawData) => {
                                                return rawData.expressFee ? roundValue(rawData.expressFee) : 0;
                                            }
                                        },
                                        {
                                            displayName: `XL Handling Fee (${currency})`, key: "xlPackageHandlingFee", onPrintFn: (rawData) => {
                                                return rawData.xlPackageHandlingFee ? roundValue(rawData.xlPackageHandlingFee) : 0;
                                            }
                                        },
                                        {
                                            displayName: `Delivery Fee (${currency})`, key: "merchantPrice", onPrintFn: (rawData) => {
                                                return rawData.priceType === "VL_RATE" ? roundValue(rawData.merchantPrice) : 0;
                                            }
                                        },
                                        {
                                            displayName: `Delivery Flat Fee (${currency})`, key: "merchantFlatPrice", onPrintFn: (rawData) => {
                                                return rawData.priceType === "VL_RATE" ? 0 : roundValue(rawData.merchantFlatPrice);
                                            }
                                        },
                                        {
                                            displayName: `Total Fee (${currency})`, key: "totalPrice", onPrintFn: (rawData) => {
                                                return rawData.priceType === "VL_RATE" ? roundValue(rawData.totalPrice) : 0;
                                            }
                                        },
                                        {
                                            displayName: `Total Flat Fee (${currency})`, key: "totalFlatPrice", onPrintFn: (rawData) => {
                                                return rawData.priceType === "VL_RATE" ? 0 : roundValue(rawData.totalFlatPrice);
                                            }
                                        },
                                    ]}
                                    tableBodyLastComponent={TableLastRow}
                                    tableBodyLastProps={get(coreState, `dataTableResponses.${reconciliationReportDedicatedAPI.key}.totalsRow`, {})}
                                    isForceShowLoader={this.state.loaderStatus}
                                />
                            </div>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
        coreState: state.CoreState,
    };
};

export default connect(mapStateToProps, null)(ReconciliationReportView);
