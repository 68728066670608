import React, { Component, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import JsBarcode from "jsbarcode";
import { get } from "lodash";

import { FormWrapper, InputButton } from "../../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import { getManifestListDedicatedAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { printModalHelperFn } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";


class ManifestPrintModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShow: false
        };
    }

    onClickFn = () => {
        this.setState({ isShow: true })
    };

    render() {
        return (
            <React.Fragment>
                <InputButton
                    elementWrapperStyle={"mr-2"}
                    btnText={"Print"}
                    onClickBtnFn={() => this.onClickFn()}
                    startIcon={"mdi mdi-printer"}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Manifest"}
                    modalType={modalTypes.large}
                    modalBodyComponent={<ManifestPrintBody row={this.props.manifest} />}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintManifestBtn />
                    }
                />
            </React.Fragment>
        );
    }
}

const ManifestPrintBody = (props) => {
    const formState = useSelector(state => state.FormData);

    useEffect(() => {
        if (get(formState, `${getManifestListDedicatedAPI.key}_print._onLoad`, true) === false && get(formState, `${getManifestListDedicatedAPI.key}_print_published_packages._onLoad`, true) === false) {
            JsBarcode("#manifestLabelBarcode", get(formState, `${getManifestListDedicatedAPI.key}_print.code`, ""), {
                width: 1,
                height: 40,
                margin: 0,
                fontSize: 15
            })

            JsBarcode(".articleID").init();
        }
    }, [
        get(formState, `${getManifestListDedicatedAPI.key}_print._onLoad`, true),
        get(formState, `${getManifestListDedicatedAPI.key}_print_published_packages._onLoad`, true)
    ])

    const getPackagesData = () => {
        if (get(formState, `${getManifestListDedicatedAPI.key}_print.status`, "") === "DRAFT") {
            return get(formState, `${getManifestListDedicatedAPI.key}_print.packages`, []);
        } else {
            return get(formState, `${getManifestListDedicatedAPI.key}_print_published_packages.packages`, []);
        }
    }

    return (
        <div id={"print_preview"}>
            <div className={"printPreviewWrapper"}>
                <FormWrapper
                    setGroupName={`${getManifestListDedicatedAPI.key}_print`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getManifestListDedicatedAPI.url}/${get(props, `row.id`, "")}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            code: response.code,
                            merchant: response.merchant,
                            driver: response.driver,
                            packages: response.packages,
                            locationPick: response.locationPick,
                            pickAt: response.pickAt,
                            status: response.status,
                            "_onLoad": false,
                        }
                    }}
                >
                    <FormWrapper
                        setGroupName={`${getManifestListDedicatedAPI.key}_print_published_packages`}
                        onDestroyUnsetFormObject={true}
                        apiUrl={`${getManifestListDedicatedAPI.url}/${get(props, `row.id`, "")}/published-packages/all`}
                        onRebuildResponseFn={(response) => {
                            return {
                                packages: response,
                                "_onLoad": false,
                            }
                        }}
                    >
                        <div className={"row"}>
                            <div className={"col-sm-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Merchant Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getManifestListDedicatedAPI.key}_print.merchant.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Merchant Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getManifestListDedicatedAPI.key}_print.merchant.code`, "")}</dt>

                                    <dd className={"col-sm-5"}>Manifest Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getManifestListDedicatedAPI.key}_print.code`, "")}</dt>

                                    <dd className={"col-sm-5"}></dd>
                                    <dt className={"col-sm-7"}><img id="manifestLabelBarcode" /></dt>

                                    {/*<dd className={"col-sm-5"}>Manifest Special Notes</dd>*/}
                                    {/*<dt className={"col-sm-7"}>{get(props, `row.notes`, "-")}</dt>*/}
                                </dl>
                            </div>

                            <div className={"col-sm-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Pick Up Details</dd>
                                    <dt className={"col-sm-7"}>
                                        {
                                            get(formState, `${getManifestListDedicatedAPI.key}_print.locationPick.address`, "")
                                        }
                                        ,
                                        {
                                            get(formState, `${getManifestListDedicatedAPI.key}_print.locationPick.city.name`, "")
                                        }
                                    </dt>

                                    <dd className={"col-sm-5"}>Pick Up Date</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getManifestListDedicatedAPI.key}_print.pickAt`, "") ? dateObjectToString(get(formState, `${getManifestListDedicatedAPI.key}_print.pickAt`, ""), "YY-mm-dd") : "-"}</dt>

                                    <dd className={"col-sm-5"}>Driver Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getManifestListDedicatedAPI.key}_print.driver.name`, "-")}</dt>
                                </dl>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-sm-12"}>
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >
                                        <table id="tech-companies-1" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Article ID</th>
                                                    <th>Consignment No. Name & Address</th>
                                                    <th>Payment Method</th>
                                                    <th>Signature Required</th>
                                                    <th>ID Verification Required</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    getPackagesData().map((bodyData, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <svg
                                                                        className="articleID"
                                                                        jsbarcode-value={get(bodyData, "articleNo", "")}
                                                                        jsbarcode-width="1"
                                                                        jsbarcode-height="35"
                                                                        jsbarcode-margin="0"
                                                                        jsbarcode-fontsize="15"

                                                                    />
                                                                </td>
                                                                <td>
                                                                    {get(bodyData, "order.consignmentNo", "")}<br />
                                                                    {get(bodyData, "order.name", "")}<br />
                                                                    {get(bodyData, "order.shippingLocation.name", "")}<br />
                                                                    {get(bodyData, "order.shippingLocation.address", "")}
                                                                </td>
                                                                <td>{get(bodyData, "order.paymentMethod", "")}</td>
                                                                <td>
                                                                    {
                                                                        (get(bodyData, "order.signatureRequired", "") === true) ? (
                                                                            "Yes"
                                                                        ) : (
                                                                            "No"
                                                                        )
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        (get(bodyData, "order.idVerificationRequired", "") === true) ? (
                                                                            "Yes"
                                                                        ) : (
                                                                            "No"
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormWrapper>
                </FormWrapper>
            </div>
        </div>
    )
}

const PrintManifestBtn = () => {
    return (
        <InputButton
            btnText={"Print"}
            onClickBtnFn={() => printModalHelperFn("print_preview")}
        />
    )
}

export {
    ManifestPrintModal,
    ManifestPrintBody,
    PrintManifestBtn
}
